<template>
  <MainPage>
    <template v-slot:content>
      <router-view/>
    </template>
  </MainPage>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import {Component, Vue} from 'vue-property-decorator'
import Page from "germanium-vue-patternfly/src/Page";
import Card from "germanium-vue-patternfly/src/Card";
import MainPage from "@/components/app/MainPage";

@Component({
  components: {MainPage, Card, Page}
})
export default class App extends Vue {
}
</script>