
import {Component, Vue} from 'vue-property-decorator';
import TargetView from "@/components/target/TargetView.vue";
import {targetHelper} from "@/model/targets";

@Component({
  components: {TargetView}
})
export default class Plan extends Vue {
  get selectedTarget() {
    return targetHelper.selectedTarget
  }
}
