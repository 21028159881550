
import {Component, Prop, Vue} from 'vue-property-decorator'
import LayoutSplit from "germanium-vue-patternfly/src/LayoutSplit.vue";

@Component({
  components: {LayoutSplit}
})
export default class InlineLabelEditable extends Vue {
  edited = false

  @Prop({default: ''}) value!: any
  originalValue!: any

  $refs!: {
    htmlInput: HTMLInputElement,
  }

  beforeMount() {
    this.originalValue = this.value
  }

  startEditing() {
    this.originalValue = this.value
    this.edited = true
  }

  finishEditing() {
    if (!this.isValid) {
      return
    }

    this.edited = false
    this.$emit('finishedit', this.$refs.htmlInput.value)
  }

  cancelEditing() {
    this.$emit('input', this.originalValue)
    this.edited = false
    this.$emit('canceledit', this.originalValue)
  }

  get isValid() {
    return !!this.value
  }
}
