import Vue from "vue";

export function initFocusDirective() {
    Vue.directive('focus', {
        // When the bound element is inserted into the DOM...
        inserted: function (el) {
            // Focus the element
            el.focus()
        }
    });
}