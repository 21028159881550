import {Action} from "@/model/actionmanager";
import {targetService} from "@/model/targets";
import {TargetRegistration} from "@/model";

export class TargetDelete implements Action {
    private readonly selectedKey: string

    private targetRegistration!: TargetRegistration

    constructor(selectedKey: string) {
        this.selectedKey = selectedKey;
    }

    execute() {
        this.targetRegistration = targetService.deleteTarget(this.selectedKey)
    }

    undo() {
        targetService.restoreTarget(this.targetRegistration)
    }

    redo() {
        this.targetRegistration = targetService.deleteTarget(this.selectedKey)
    }
}