import {Action} from "@/model/actionmanager";
import {targetService} from "@/model/targets";

export class CompletedAction implements Action {
    private readonly key: string
    private readonly checked: boolean
    
    constructor(key: string, checked: boolean) {
        this.key = key
        this.checked = checked
    }
    
    execute(): any {
        targetService.setCompleted(this.key, this.checked)
    }

    undo(): any {
        targetService.setCompleted(this.key, !this.checked)
    }

    redo(): any {
        targetService.setCompleted(this.key, this.checked)
    }
}
