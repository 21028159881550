
import {Component, Vue} from 'vue-property-decorator'
import {targetHelper} from "@/model/targets";
import {Target} from "@/model";
import InlineLabelEditable from "@/components/InlineLabelEditable.vue";

@Component({
  components: {InlineLabelEditable}
})
export default class TargetBreadcrumb extends Vue {
  // FIXME: initially the idea was to have the targetSupport as a shared
  // Vue component, so the @Computed properties get computed only once
  get selectedTarget(): Target {
    return targetHelper.selectedTarget
  }

  get ancestors(): Array<Target> {
    return targetHelper.selectedTargetAncestors
  }

  selectTarget(ancestor: Target) : void {
    targetHelper.setActiveTarget(ancestor.key)
  }

  selectEditedTarget() : void {
    targetHelper.setEditedTarget(this.selectedTarget.key)
  }
}
