
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Target } from "@/model";
import { targetHelper } from "@/model/targets";
import Label from "germanium-vue-patternfly/src/Label.vue";
import GeDndPreview from "germanium-vue-dnd/src/GeDndPreview.vue"
import { actionManager } from "@/model/actionmanager";
import { CompletedAction } from "@/model/actions/completed";

@Component({
  components: {Label, GeDndPreview}
})
export default class TargetListItem extends Vue {
  @Prop({}) target!: Target

  $refs!: {
    checkbox: HTMLInputElement
  }

  toggleCompleted(target: Target) {
    actionManager.execute(
        new CompletedAction(
          target.key,
          this.$refs.checkbox.checked,
        ),
    )
  }

  onDoubleClick() {
    targetHelper.setActiveTarget(this.target.key)
  }

  selectEditedTarget() {
    targetHelper.setEditedTarget(this.target.key)
  }

  get childTargets(): Array<Target> {
    return targetHelper.filteredAndSortedItems(this.target)
  }

  get isCompleted() : boolean {
    return !!this.target.completed
  }

  get titleCssClasses() {
    const result: {[name: string] : boolean} = {
      title: true
    }

    if (this.target.completed) {
      result['target-completed'] = true
    }

    return result
  }

  get rowCssClasses() {
    const result: {[name: string] : boolean} = {
    }

    if (this.selected) {
      result['pf-m-selected'] = true
    }

    return result
  }

  get selected() {
    const editedTarget = targetHelper.editedTarget()

    if (!editedTarget) {
      return false
    }

    return editedTarget.key == this.target.key
  }
}
