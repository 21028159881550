import Vue from 'vue'
import { initialOaasObjectivoSetup } from "@/model/persistence/objectivo-storage";

export enum TargetPriority {
    A = "must",
    B = "should",
    C = "nice",
    D = "delegate",
    E = "eliminate",
}

export interface TargetBase {
    key: string  // unique ID that we can use in v-for
    parentKey?: string // where the target currently resides
}

/**
 * A single target that we're trying to achieve
 */
export interface Target extends TargetBase {
    // from TargetBase:
    key: string  // unique ID that we can use in v-for
    parentKey: string
    // self
    completed?: Date // when the target was completed. can be null if not completed.
    created: Date // when was the target created
    updated?: Date // when something changed in the target
    title: string
    description?: string
    priority?: TargetPriority
    notes?: string
    dependsOn?: Array<string>
    children?: Array<string>
}

/**
 * A link to a target that we want to achieve.
 */
export interface TargetLink extends TargetBase{
    // from TargetBase
    key: string  // unique ID that we can use in v-for
    parentKey: string
    // self
    targetKey: string
}

/**
 * Keeps track where a target is in the tree. This includes the index
 * inside the parent. The parent key itself is contained in the target.
 *
 * Like this, even if we delete a node, by just keeping its target
 * registration we can restore it in the same parent, at the same location
 * where it was originally.
 */
export interface TargetRegistration {
    target: TargetBase,
    parentIndex: number
}

// The selectedTarget is the ID of the selected node, since then we can use it
// anywhere correctly, vs a hierarchical path that would break whenever we move
// a target in our model. This also means that we need to keep back references.
export interface RootModel {
    targets: { [name: string] : TargetBase },
    selectedTarget?: string,
    editedTarget?: string,
    showCompletedTargets?: boolean,
}

export const DEFAULT_EMPTY_MODEL = {
    targets: {
        root: {
            key: "root",
            title: "Placeholder container for all other targets",
            created: new Date(),
        },
    },
    selectedTarget: 'root', // this is the key id of the target
    editedTarget: '', // the target we want to edit in the panel
    showCompletedTargets: false, // should we display the completed targets
}

/**
 * Global model of the application. Contains all the targets, and
 * the ui state.
 */
export const model: RootModel = JSON.parse(JSON.stringify(DEFAULT_EMPTY_MODEL))

// we make all the keys reactive
Object.keys(model).forEach(it => {
    Vue.set(model, it, (model as any)[it]);
})

// call the oaas setup for objectivo
// initialOaasObjectivoSetup()
