import {v4} from 'uuid';
import {DEFAULT_EMPTY_MODEL, model, TargetBase, Target, TargetRegistration, TargetLink} from "@/model/index";
import Vue from "vue";

/**
 * This helper makes finding the targets needed to render in
 * the UI easier.
 * FIXME: move? I'm not sure it really belongs here
 */
class TargetHelper {
    /**
     * Obtains the selected target.
     */
    get selectedTarget(): TargetBase {
        return this.selectedTargets[this.selectedTargets.length - 1]
    }

    /**
     * Obtains all the ancestors of the currently selected target.
     */
    get selectedTargetAncestors(): Array<TargetBase> {
        const result: Array<TargetBase> = [...this.selectedTargets]
        result.splice(this.selectedTargets.length - 1)

        return result
    }

    /**
     * Obtain an array with all the selected targets, parsing the selected
     * path.
     */
    get selectedTargets(): Array<TargetBase> {
        if (!model.selectedTarget) {
            throw new Error("No selected task is present in the model")
        }

        const result: Array<TargetBase> = []

        let selectedTargetKey: string | undefined = model.selectedTarget

        while (selectedTargetKey) {
            const currentTarget: TargetBase = model.targets[selectedTargetKey]

            if (!currentTarget) {
                throw new Error(`Failed finding ${selectedTargetKey} in the targets. Wanted to select ${model.selectedTarget}.`)
            }

            selectedTargetKey = currentTarget.parentKey
            result.splice(0, 0, currentTarget)
        }

        return result
    }

    /**
     * Get a list of items, by also looking at the current UI
     * settings. This should be used in computed methods in the
     * UI.
     */
    filteredAndSortedItems(target: TargetBase) : Array<TargetBase> {
        // FIXME: probably the tree and the list should have different
        // implementations since it doesn't make sense the tree to
        // reorder its elements.
        const resultData: Array<TargetBase> = []

        const resolvedTarget: Target = resolveTarget(target.key)

        // FIXME: split in a different model
        resolvedTarget.children?.map(resolveTarget)
            .filter(it => model.showCompletedTargets ? true : !it.completed)
            .forEach(it => resultData.push(it))

        return resultData
    }

    editedTarget() : Target | null {
        if (!model.editedTarget) {
            return null
        }

        return resolveTarget(model.editedTarget)
    }

    setActiveTarget(targetKey: string | null) {
        Vue.set(model, "selectedTarget", targetKey)
        Vue.set(model, "editedTarget", null)
    }

    setEditedTarget(targetKey: string | null) {
        Vue.set(model, "editedTarget", targetKey)
    }
}

/**
 * Resolves the target following the target links, until we reach a target
 * that we're trying to achieve.
 * @param key key of the link or target
 */
function resolveTarget(key: string): Target {
    let potentialResult = model.targets[key]

    while (potentialResult) {
        if ("targetKey" in potentialResult) {
            const targetKey = (<TargetLink>potentialResult).targetKey
            potentialResult = model.targets[targetKey]
            continue
        }

        return potentialResult as Target
    }

    throw new Error(`unable to resolve target for id ${key}`)
}

/**
 * Deals with operations on the services. Does everything in memory
 * against the `model.targets`. Persistence is not handled here. Nor is
 * UI.
 */
export class TargetService {
    /**
     * Create a new target into our planning.
     * @param parentKey Where does the target belong to.
     * @param title The target title to create
     */
    createTarget(parentKey: string, title: string): TargetRegistration {
        const parent = resolveTarget(parentKey)

        if (!parent) {
            throw new Error(`No parent with key ${parentKey} in targets.`)
        }

        const now = new Date();

        const result: Target = {
            key: v4(),
            parentKey,
            title,
            created: now,
            updated: now,
        }

        if (!parent.children) {
            Vue.set(parent, "children", [])
        }

        parent.children!.push(result.key)
        Vue.set(model.targets, result.key, result)

        return {
            target: result,
            parentIndex: parent.children!.length - 1,
        }
    }

    /**
     * 
     * @param targetKey 
     * @param parentKey 
     * @param index 
     * @returns 
     */
    createLink(targetKey: string, parentKey: string, index: string): TargetRegistration {
        const parent = resolveTarget(parentKey)
        const target = resolveTarget(targetKey) // we try to keep direct links

        if (!parent) {
            throw new Error(`No parent with key ${parentKey} in targets.`)
        }

        const result: TargetLink = {
            key: v4(),
            targetKey: target.key,
            parentKey,
        }

        if (!parent.children) {
            Vue.set(parent, "children", [])
        }

        parent.children!.push(result.key)
        Vue.set(model.targets, result.key, result)

        return {
            target: result,
            parentIndex: parent.children!.length - 1,
        }
    }

    /**
     * Update the title for a given target
     * @param targetKey
     * @param title
     */
    setTitle(targetKey: string, title: string): void {
        const target = model.targets[targetKey]
        Vue.set(target, "title", title)
    }

    /**
     * Updates the description for a given target.
     * @param targetKey
     * @param description
     */
    setDescription(targetKey: string, description: string) : void {
        const target = model.targets[targetKey]
        Vue.set(target, "description", description)
    }

    /**
     * Restores (i.e. not recreates) a target in the model.
     * @param targetRegistration
     */
    restoreTarget(targetRegistration: TargetRegistration) {
        const parent = resolveTarget(targetRegistration.target.parentKey!)

        if (!parent) {
            throw new Error(`No parent with key ${targetRegistration.target.parentKey!} in targets.`)
        }

        if (!parent.children) {
            Vue.set(parent, "children", [])
        }

        // we don't blindly append, since we might need to restore in the
        // middle of the children array
        parent.children!.splice(
            targetRegistration.parentIndex,
            0,
            targetRegistration.target.key)
        Vue.set(
            model.targets,
            targetRegistration.target.key,
            targetRegistration.target)
    }

    /**
     * Remove a target, including potential children
     * @param targetKey Key of the target te be deleted.
     * @return the index where the element was originally in its parent
     */
    deleteTarget(targetKey: string): TargetRegistration {
        const deletionQueue = [targetKey]
        const keysMarkedForDeletion: Array<string> = []
        const parentKey: string = model.targets[targetKey].parentKey as string

        // We first collect all the keys we want to remove, then we remove them in
        // reverse order, since otherwise their parents might be gone. We remove them
        // one by one to:
        // 1. cleanup the model.targets correctly
        // 2. remove cross dependencies
        while (deletionQueue.length) {
            // this can't be undefined, since we check the queue before
            const targetKeyToDelete: string = deletionQueue.pop() as string;

            keysMarkedForDeletion.push(targetKeyToDelete)

            if (!targetKeyToDelete) {
                throw new Error(`Invalid key ${targetKeyToDelete} sent for deletion`)
            }

            const targetToDelete = model.targets[targetKeyToDelete]

            if (!targetToDelete) {
                throw new Error(`No target with key ${targetKeyToDelete} found for deletion`)
            }

            const parentKey = targetToDelete.parentKey;
            if (!parentKey) {
                throw new Error(`The target marked for deletion ${targetKey} has no ${parentKey}`)
            }

            if ("children" in targetToDelete) {
                (targetToDelete as Target).children?.forEach(it => deletionQueue.push(it))
            }
        }

        const target = model.targets[targetKey]

        keysMarkedForDeletion.reverse().forEach(it => {
            const toBeRemoved = model.targets[it]
            const parent = model.targets[toBeRemoved.parentKey as string]

            Vue.delete(model.targets, it)
        })

        // A parent of a target base must be a target, since it must contain
        // children
        const parent = model.targets[parentKey] as Target
        const parentIndex: number = parent.children?.indexOf(targetKey) as number;
        parent.children?.splice(parentIndex, 1)

        if (!parent.children?.length) {
            Vue.delete(parent, "children")
        }

        return {
            target,
            parentIndex,
        }
    }

    /**
     * Move a target, either to a new parent, either to a
     * new index (in the children list of a target)
     * @param newParentKey
     * @param targetKey
     * @param index
     * @returns old index into the old parent
     */
    moveTarget(newParentKey: string, targetKey: string, index: number = -1): number {
        const target = model.targets[targetKey]
        const resolvedTarget = resolveTarget(targetKey)

        if (!target.parentKey) {
            if (target.key == "root") {
                throw new Error("Cannot move root node. (?!)")
            }

            throw new Error(`BUG: target has no parent: ${target.key}:${resolvedTarget.title}.`)
        }

        // The target we want to move must reside in an actual target,
        // however, we might want to drop onto a link, hence we need to find
        // the actual parent to move the item to.
        const currentParent = model.targets[target.parentKey] as Target
        const newParent = resolveTarget(newParentKey)

        if (newParentIsChildOrSelf(newParentKey, targetKey)) {
            throw new Error("Cannot move a node into its child.")
        }

        // we're only changing the order
        const currentIndex = currentParent.children?.indexOf(target.key)

        if (typeof currentIndex == "undefined" || currentIndex < 0) {
            throw new Error(`Unable to find ${target.key}:${resolvedTarget.title} in ${currentParent.key}:${currentParent.title} index was ${currentIndex}`)
        }

        console.log(
            `moving ${resolvedTarget.title} from ${currentParent.title}:${currentIndex}`,
            "to",
            `${newParent.title}:${index}`,
        )

        currentParent.children!.splice(currentIndex, 1)

        if (typeof newParent.children === "undefined") {
            newParent.children = []
        }

        if (index < 0) {
            newParent.children!.push(target.key)
        } else {
            newParent.children!.splice(index, 0, target.key)
        }
        
        target.parentKey = newParentKey

        const now = new Date()

        // mark all the targets involved as updated
        Vue.set(currentParent, "updated", now)
        Vue.set(newParent, "updated", now)
        Vue.set(target, "updated", now)

        return currentIndex
    }

    /**
     * Removes all the data except the root.
     */
    _nuke() {
        const rootModel = JSON.parse(JSON.stringify(DEFAULT_EMPTY_MODEL))
        Vue.set(model, "targets", rootModel.targets)
    }

    /**
     * Sets the target as completed, or not yet done.
     * @param targetKey
     * @param value
     */
    setCompleted(targetKey: string, value: boolean) {
        const target = model.targets[targetKey]

        if (!target) {
            throw new Error(`Unable to find target with id ${targetKey} in targets`)
        }

        if (!value) {
            Vue.delete(target, "completed")
            return
        }

        Vue.set(target, "completed", new Date())
    }
}

function newParentIsChildOrSelf(newParentKey: string, targetKey: string): boolean {
    let parentKey: string | undefined = newParentKey

    while (parentKey) {
        if (parentKey == targetKey) {
            return true
        }

        parentKey = model.targets[parentKey]?.parentKey
    }

    return false
}

export const targetHelper = new TargetHelper()

// FIXME: this should be swappable
export const targetService = new TargetService()
