
import {Component, Vue} from 'vue-property-decorator'
import Expandable from "germanium-vue-patternfly/src/Expandable.vue";
import TreeNodeView from "@/components/app/TreeNodeView.vue";

import {model} from '@/model';
import Icon from "germanium-vue-patternfly/src/Icon.vue";
import {targetHelper} from "@/model/targets";

@Component({
  components: {Icon, TreeNodeView, Expandable}
})
export default class TargetTree extends Vue {
  model = model

  selectRoot() {
    targetHelper.setActiveTarget("root")
  }

  get filteredRootChildren() {
    return targetHelper.filteredAndSortedItems(model.targets.root)
  }
}
