
import {Component, Prop, Vue} from 'vue-property-decorator'
import LayoutSplit from "germanium-vue-patternfly/src/LayoutSplit.vue";
import Markdown from "@/components/Markdown.vue";

@Component({
  components: {Markdown, LayoutSplit}
})
export default class InlineMarkdownEditable extends Vue {
  edited = false

  @Prop({}) emptyLabel: string = ""

  @Prop({}) value!: string
  originalValue!: string

  beforeMount() {
    this.originalValue = this.value
  }

  startEditing() {
    this.originalValue = this.value
    this.edited = true
  }

  stopEditing() {
    if (!this.isValid) {
      return
    }

    this.edited = false
  }

  cancelEditing() {
    this.$emit('input', this.originalValue)
    this.edited = false
  }

  get isValid() {
    return !!this.value
  }
}
