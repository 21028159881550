
import {Component, Prop, Vue} from 'vue-property-decorator'
import LayoutSplit from "germanium-vue-patternfly/src/LayoutSplit.vue";
import Markdown from "@/components/Markdown.vue";
import {Editor, EditorContent, EditorMenuBubble} from 'tiptap'
import Icon from "germanium-vue-patternfly/src/Icon.vue";
import ToggleGroup from "germanium-vue-patternfly/src/ToggleGroup.vue";
import ToggleButton from "germanium-vue-patternfly/src/ToggleButton.vue";
import Flex from "germanium-vue-patternfly/src/Flex.vue";
import FlexItem from "germanium-vue-patternfly/src/FlexItem.vue";
import {Bold, BulletList, History, Italic, Link, ListItem, Strike, Underline,} from 'tiptap-extensions';

@Component({
  components: {FlexItem, Flex, ToggleButton, ToggleGroup, Icon, Markdown, LayoutSplit, EditorContent, EditorMenuBubble}
})
export default class InlineTipTapEditable extends Vue {
  edited = false
  editor!: Editor

  @Prop({default: ''}) emptyLabel!: string

  @Prop({default: ''}) value!: string
  originalValue!: string

  beforeMount() {
    this.originalValue = this.value
  }

  beforeUpdate() {
    this.editor.setContent(this.value);

    // FIXME: this is a super dirty hack, since it steals focus
    setTimeout(() => {
      this.editor.view.dom.focus();
    }, 0)
  }

  mounted() {
    this.editor = new Editor({
      extensions: [
        new ListItem(),
        new BulletList(),
        new Link(),
        new Bold(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      content: this.originalValue,
      onUpdate: (state: any) => {
        this.$emit('input', state.getHTML())
      }
    })
  }

  beforeDestroy() {
    this.editor.destroy()
  }

  startEditing() {
    this.originalValue = this.value
    this.edited = true
  }

  finishEditing() {
    this.edited = false
    this.$emit('finishedit', this.editor.getHTML())
  }

  cancelEditing() {
    this.$emit('input', this.originalValue)
    this.edited = false
    this.$emit('canceledit', this.originalValue)
  }
}
