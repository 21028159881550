import {PersistenceProvider} from "@/model/persistence/index";
import {DEFAULT_EMPTY_MODEL, model, RootModel} from "@/model";
import Vue from "vue";
import * as yaml from "yamljs"

export class ElectronStoragePersistenceProvider implements PersistenceProvider {
    async load(model: RootModel): Promise<RootModel> {
        let data = null;

        try {
            data = await window.electronApi.readModelFile()
        } catch (e) {
            console.error(e);
        }

        if (data == null) {
            let emptyModel = yaml.parse(yaml.stringify(DEFAULT_EMPTY_MODEL));
            Object.keys(emptyModel).forEach(
                key => Vue.set(model, key, emptyModel[key])
            )

            return emptyModel
        }

        const loadedModel = yaml.parse(data);
        Object.keys(loadedModel).forEach(
            key => Vue.set(model, key, loadedModel[key])
        )

        return loadedModel
    }

    async persist(): Promise<void> {
        const data = yaml.stringify(model, /* inline */ 100, /* spaces */ 2)
        try {
            await window.electronApi.writeModelFile(data)
        } catch (e) {
            return Promise.reject(e)
        }

        return Promise.resolve()
    }

}