
import {Component, Vue, Watch} from 'vue-property-decorator'
import Page from "germanium-vue-patternfly/src/Page.vue";
import TopNavigation from "germanium-vue-patternfly/src/TopNavigation.vue";
import TopNavigationLink from "germanium-vue-patternfly/src/TopNavigationLink.vue";
import GeDndPreview from 'germanium-vue-dnd/src/GeDndPreview.vue'
import TargetTree from "@/components/app/TargetTree.vue";
import {model} from "@/model";
import {PersistenceProvider} from "@/model/persistence";
import {getPersistenceService} from "@/model/persistence/resolver";

const persistenceProvider: PersistenceProvider = getPersistenceService()

@Component({
  components: {
    GeDndPreview,
    Page,
    TargetTree,
    TopNavigation,
    TopNavigationLink,
  }
})
export default class MainPage extends Vue {
  model = model

  // FIXME: I don't really enjoy the persistence being part of the main page
  @Watch("model", {deep: true})
  async watchModelChanges() {
    await persistenceProvider.persist()
  }
}
