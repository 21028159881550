import {Action} from "@/model/actionmanager";
import {targetService} from "@/model/targets";
import {TargetRegistration} from "@/model";

export class TargetCreate implements Action {
    private readonly parentKey: string
    private readonly title: string

    private targetRegistration!: TargetRegistration

    constructor(parentKey: string, title: string) {
        this.parentKey = parentKey;
        this.title = title;
    }

    execute() {
        this.targetRegistration = targetService.createTarget(this.parentKey, this.title)
    }

    undo() {
        targetService.deleteTarget(this.targetRegistration.target.key)
    }

    redo() {
        targetService.restoreTarget(this.targetRegistration)
    }
}