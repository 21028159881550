
/* eslint no-console: ["off", { allow: ["warn", "error"] }] */

import Icon from 'germanium-vue-patternfly/src/Icon.vue'

import { Component, Prop, Vue } from 'vue-property-decorator';
import { model, RootModel, Target } from "@/model";
import { targetHelper } from "@/model/targets";
import { actionManager } from "@/model/actionmanager";
import Tooltip from "germanium-vue-patternfly/src/Tooltip.vue";
import GeDndPreview from "germanium-vue-dnd/src/GeDndPreview.vue"
import { TargetMove } from "@/model/actions/target-move";

@Component({
  name: 'TreeNodeView',
  components: {
    Tooltip,
    Icon,
    TreeNodeView,
    GeDndPreview,
  }
})
export default class TreeNodeView extends Vue {
  @Prop() target!: Target
  model: RootModel = model

  expandedState: boolean = false

  dragOver: boolean = false

  get cssIconLabelClasses() {
    const result: {[name: string]: boolean} = {
      "tree-icon-label": true,
    };

    if (this.dragOver) {
      result["drag-over"] = true
    }

    if (this.target.completed) {
      result["target-completed"] = true
    }

    return result;
  }

  get treeTitleCssClasses() {
    const result: {[name: string]: boolean} = {
      "tree-label": true,
    };

    if (this.target.completed) {
      result["target-completed"] = true
    }

    return result;
  }

  get cssIcon() {
    if (this.expandedState) {
      return "fa-folder-open";
    } else {
      return "fa-folder";
    }
  }

  get cssPlusClasses() {
    const result: {[name: string]: boolean} = {
    };

    result['tree-plus'] = true

    if (this.expandedState) {
      result['expanded'] = true
    }

    return result;
  }

  onDrop(data: Target, ev: any, index: number) {
    const targetMoveAction = new TargetMove(
      data.key,
      data.parentKey as string,
      this.target.key as string,
      index,
    );
    actionManager.execute(targetMoveAction)
  }

  onToggleExpand() {
    this.expandedState = ! this.expandedState
  }

  onClickNode() {
    targetHelper.setActiveTarget(this.target.key)
    targetHelper.setEditedTarget(this.target.key)
  }

  dragOverCheck() {
    return !! this.target.children?.length
  }

  get filteredTargetChildren(): Array<Target> {
    return targetHelper.filteredAndSortedItems(this.target)
  }
}
