import {Action} from "@/model/actionmanager";
import {targetService} from "@/model/targets";

export class TargetMove implements Action {

    private key: string
    private oldParentKey: string
    private oldIndex: number
    private newParentKey: string
    private newIndex: number

    constructor(
        key: string,
        oldParentKey: string,
        newParentKey: string,
        newIndex: number,
    ) {
        this.key = key;
        this.oldIndex = -1;
        this.oldParentKey = oldParentKey
        this.newParentKey = newParentKey
        this.newIndex = newIndex          
    }

    execute() {
        this.oldIndex = targetService.moveTarget(this.newParentKey, this.key, this.newIndex)
    }

    undo() {
        targetService.moveTarget(this.oldParentKey, this.key, this.oldIndex)
    }

    redo() {
        targetService.moveTarget(this.newParentKey, this.key, this.newIndex)
    }
}