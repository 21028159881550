
import {Component, Prop, Vue} from 'vue-property-decorator'
import { Remarkable } from 'remarkable'

const md = new Remarkable()

@Component({})
export default class Markdown extends Vue {
  @Prop({}) content!: string

  get markdownContent() {
    return md.render(this.content)
  }
}
