import {Action} from "@/model/actionmanager";
import {targetService} from "@/model/targets";

export class TargetTitleAction implements Action {
    private readonly targetKey: string
    private readonly oldTitle: string
    private readonly newTitle: string


    constructor(targetKey: string,
                oldTitle: string,
                newTitle: string) {
        this.targetKey = targetKey;
        this.oldTitle = oldTitle;
        this.newTitle = newTitle;
    }

    execute() {
        targetService.setTitle(this.targetKey, this.newTitle)
    }

    undo() {
        targetService.setTitle(this.targetKey, this.oldTitle)
    }

    redo() {
        targetService.setTitle(this.targetKey, this.newTitle)
    }
}