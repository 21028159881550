
import {Component, Prop, Vue} from 'vue-property-decorator'
import FlexItem from "germanium-vue-patternfly/src/FlexItem.vue";
import Card from "germanium-vue-patternfly/src/Card.vue";
import Flex from "germanium-vue-patternfly/src/Flex.vue";
import {Target} from "@/model";
import TargetBreadcrumb from "@/components/target/TargetBreadcrumb.vue";
import Icon from "germanium-vue-patternfly/src/Icon.vue";
import TargetListItem from "@/components/target/TargetListItem.vue";
import TargetList from "@/components/target/TargetList.vue";
import LayoutSplit from "germanium-vue-patternfly/src/LayoutSplit.vue";
import {targetHelper} from "@/model/targets";
import InlineLabelEditable from "@/components/InlineLabelEditable.vue";
import Markdown from "@/components/Markdown.vue";
import InlineMarkdownEditable from "@/components/InlineMarkdownEditable.vue";
import InlineTipTapEditable from "@/components/InlineTipTapEditable.vue";
import {actionManager} from "@/model/actionmanager";
import {TargetDescriptionAction} from "@/model/actions/target-description";
import {TargetTitleAction} from "@/model/actions/target-title";

@Component({
  components: {
    InlineTipTapEditable,
    InlineMarkdownEditable,
    Markdown,
    InlineLabelEditable, LayoutSplit, TargetList, TargetListItem, Icon, TargetBreadcrumb, Flex, Card, FlexItem}
})
export default class TargetView extends Vue {
  @Prop({})
  target!: Target  // the currently selected target we want to achieve
                  // we display the children from this one in the list

  get editedTarget() : Target | null {
    return targetHelper.editedTarget()
  }

  updateTargetTitle(content: string) : void {
    if (!this.editedTarget) {
      console.warn("The edited target is null. Probably a bug")
      return
    }

    actionManager.execute(new TargetTitleAction(
        this.editedTarget.key,
        this.editedTarget.title as string,
        content,
    ))
  }

  updateTargetDescription(content: string) : void {
    if (!this.editedTarget) {
      console.warn("The edited target is null. Probably a bug")
      return
    }

    actionManager.execute(new TargetDescriptionAction(
        this.editedTarget.key,
        this.editedTarget.description as string,
        content,
    ))
  }
}
