
import {Component, Prop, Vue} from 'vue-property-decorator'
import TargetListItem from "@/components/target/TargetListItem.vue";
import { RootModel, Target, model } from "@/model";
import Icon from "germanium-vue-patternfly/src/Icon.vue";
import {targetHelper} from "@/model/targets";
import Switcher from "germanium-vue-patternfly/src/Switch.vue";
import Button from "germanium-vue-patternfly/src/Button.vue";
import {actionManager} from "@/model/actionmanager";
import {TargetCreate} from "@/model/actions/target-create";
import {TargetDelete} from "@/model/actions/target-delete";
import { TargetMove } from "@/model/actions/target-move";

@Component({
  components: {Button, Switcher, Icon, TargetListItem}
})
export default class TargetList extends Vue {
  @Prop({}) target!: Target
  model: RootModel = model

  $refs!: {
    newTargetLabel: HTMLInputElement,
  }

  get childTargets(): Array<Target> {
    return targetHelper.filteredAndSortedItems(this.target)
  }

  createTarget = () => {
    console.log("create target")

    if (!this.$refs.newTargetLabel.value) {
      return
    }

    const targetCreateAction = new TargetCreate(
        this.target.key,
        this.$refs.newTargetLabel.value
    );
    actionManager.execute(targetCreateAction)

    this.$refs.newTargetLabel.value = ""
  }

  onDrop(data: Target, ev: any, index: number) {
    const targetMoveAction = new TargetMove(
      data.key,
      data.parentKey as string,
      this.target.key as string,
      index - 1, // we substract the tbody for the header
    );
    actionManager.execute(targetMoveAction)
  }

  onShowCompletedTargetsChange(ev: InputEvent) {
    model.showCompletedTargets = (ev!.target as HTMLInputElement).checked
  }

  onDeleteClicked = () => {
    const editedTarget = targetHelper.editedTarget()

    if (!editedTarget) {
      return;
    }

    // we're deleting the current node that we're viewing
    if (editedTarget.key == this.target.key) {
      targetHelper.setActiveTarget(this.target.parentKey)
    }

    targetHelper.setEditedTarget(null)

    actionManager.execute(new TargetDelete(editedTarget.key))
  }

  get isDeletionDisabled() {
    return targetHelper.editedTarget() == null
  }
}
