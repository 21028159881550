export interface Action {
    execute() : void
    undo() : void
    redo() : void
}


class ActionManager {
    history: Array<Action> = []
    index: number = -1  // last executed action

    execute(action: Action) {
        console.log('run action')

        // we have actions for redo that we'll drop now
        if (this.index < this.history.length - 1) {
            this.history.splice(this.index + 1, this.history.length - this.index - 1)
        }

        action.execute()
        this.history.push(action)
        this.index = this.history.length - 1
    }

    undo() {
        if (this.index < 0) {
            console.log("There's nothing to undo")
            return // => no more actions to process
        }

        const executedAction = this.history[this.index]

        console.log(`Calling undo on ${executedAction}`)

        executedAction.undo()

        this.index -= 1
    }

    redo() {
        if (this.index >= this.history.length - 1) {
            console.log("There's nothing to redo")
            return // => no more actions to redo
        }

        this.index += 1

        const executedAction = this.history[this.index]

        console.log(`Calling redo on ${executedAction}`)

        executedAction.redo()
    }
}

export const actionManager = new ActionManager()

import hotkeys from 'hotkeys-js';


hotkeys('ctrl+z', (event) => {
    actionManager.undo()
})

hotkeys('ctrl+y', (event) => {
    actionManager.redo()
})