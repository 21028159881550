import { render, staticRenderFns } from "./InlineLabelEditable.vue?vue&type=template&id=37956764&scoped=true&"
import script from "./InlineLabelEditable.vue?vue&type=script&lang=ts&"
export * from "./InlineLabelEditable.vue?vue&type=script&lang=ts&"
import style0 from "./InlineLabelEditable.vue?vue&type=style&index=0&id=37956764&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37956764",
  null
  
)

export default component.exports