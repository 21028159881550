import {Action} from "@/model/actionmanager";
import {targetService} from "@/model/targets";

export class TargetDescriptionAction implements Action {
    private readonly targetKey: string
    private readonly oldDescription: string
    private readonly newDescription: string


    constructor(targetKey: string,
                oldDescription: string,
                newDescription: string) {
        this.targetKey = targetKey;
        this.oldDescription = oldDescription;
        this.newDescription = newDescription;
    }

    execute() {
        targetService.setDescription(this.targetKey, this.newDescription)
    }

    undo() {
        targetService.setDescription(this.targetKey, this.oldDescription)
    }

    redo() {
        targetService.setDescription(this.targetKey, this.newDescription)
    }
}