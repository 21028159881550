import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {initDragAndDrop} from 'germanium-vue-dnd/src/main'
import {model} from "@/model";
import {getPersistenceService} from "@/model/persistence/resolver";
import {initFocusDirective} from "@/components/vue-focus";

require('@patternfly/patternfly/patternfly.scss')

initDragAndDrop()
initFocusDirective();

async function initialize() {
  Vue.config.productionTip = false
  await getPersistenceService().load(model)

  const vm = new Vue({
    router,
    render: h => h(App)
  })

  vm.$mount('#app')
}

initialize()
